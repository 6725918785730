import React, { useState } from 'react';
import missionImage from '../assets/img/mission.png';
import visionImage from '../assets/img/vision.png';
import { InView } from 'react-intersection-observer';
const MissionVision = () => {
  const [isVisible, setVisible] = useState(false);

  return (
    <section>
      <center>
        <h1>Mission & Vision</h1>
      </center>
      <br />
      <div className='row text-center my-3 p-5 '>
        <div className={'col-lg-6 animate__animated animate__zoomIn'}>
          <div
            className='jumbotron m-card b-shadow-1'
            style={{ minHeight: '406px' }}
          >
            <img alt='mission' src={missionImage} width={100} />
            <h3 style={{ textTransform: 'uppercase' }}>Mission</h3>
            <p>
              Delivering customized solutions by investing in technology and
              people and complying with applicable statutory and regulatory
              requirements to enhance portability for providing end-to-end
              supply chain services through an efficient global network
            </p>
          </div>
        </div>
        <div className={'col-lg-6 animate__animated animate__zoomIn'}>
          <div
            className='jumbotron b-shadow-1 m-card-1'
            style={{ minHeight: '406px' }}
          >
            <img alt='vision' src={visionImage} width={100} />
            <h3 style={{ textTransform: 'uppercase' }}>Vision</h3>
            <p>
              Exceeding customer’s expectations by providing innovative and
              efficient logistics solutions
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
