import React, { useState } from 'react';
import titleicon1 from '../assets/icons/title-icon-1.png';
import { InView } from 'react-intersection-observer';
import { Loading } from './Loading';
import { ErrorPage } from './ErrorPage';
import { ABOUT_US_QUERY } from '../GQL/Quries';
import { useQuery } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import MissionVision from './MissionVision';
const About = () => {
  const [isVisible, setVisible] = useState(false);
  const checkVisible = (inView, entry) => {
    if (inView) setVisible(true);
  };

  const { loading, error, data } = useQuery(ABOUT_US_QUERY);

  if (loading) return <Loading />;
  if (error) return <ErrorPage />;
  const aboutUs = data.aboutUsCollection.items[0];

  return (
    <section id='about' className='container my-5'>
      <InView
        as='span'
        onChange={(inView, entry) => checkVisible(inView, entry)}
        threshold={0}
        triggerOnce={true}
      ></InView>
      <div className='row d-flex align-items-center'>
        <div
          className={`${
            isVisible ? 'col-lg-6 animate__animated animate__fadeInUp' : ''
          }`}
        >
          <div class='section-title-right text-main-color  py-1'>
            <div class='icon'>
              <img src={titleicon1} alt='who we are' />
            </div>
            <h2 class='title-text'>Who We Are ?</h2>
          </div>
          <ReactMarkdown children={aboutUs.description} />
          <div>
            <i
              style={{ color: '#0e76a8', fontSize: '28px' }}
              className='fa fa-linkedin-square mx-2'
            ></i>

            <a
              style={{ fontSize: '20px' }}
              href='https://www.linkedin.com/company/sbeshippingline'
              target='_blank'
              rel='noreferrer'
            >
              Linkdin/sbeshippingline
            </a>
          </div>
        </div>
        <div
          className={`${
            isVisible
              ? 'col-lg-6 animate__animated animate__fadeInUp'
              : 'd-none'
          }`}
        >
          <img
            className='mt-2'
            alt='about side'
            src={aboutUs.aboutImage.url}
            width='100%'
          />
        </div>
      </div>
      <MissionVision />
    </section>
  );
};

export default About;
