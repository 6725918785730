import './App.css';
import './assets/fonts/font-awesome/css/font-awesome.min.css';
import 'animate.css';
import Slider from './Components/Slider';
import About from './Components/About';
import Services from './Components/Services';

import ContactUs from './Components/ContactUs';
import WhyUs from './Components/WhyUs';

function App() {
  return (
    <div>
      <a href='#top' className='float'>
        <i className='fa fa-arrow-up my-float' aria-hidden='true'></i>
      </a>
      <Slider />
      <div className='body-div'>
        <About />
        <Services />
        <WhyUs />
        <ContactUs />
      </div>
    </div>
  );
}

export default App;
