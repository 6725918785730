import React from 'react';

export const ErrorPage = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#08a4af',
      }}
    >
      <div className='text-center'>
        <h3>404 Not Found !</h3>
      </div>
    </div>
  );
};
