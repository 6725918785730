import React, { useState } from 'react';
import service1 from '../assets/img/service-1.jpg';
import { InView } from 'react-intersection-observer';
import { useQuery } from '@apollo/client';
import { SERVICES_QUERY } from '../GQL/Quries';
import { Loading } from './Loading';
import { ErrorPage } from './ErrorPage';
const Services = () => {
  const [isVisible, setVisible] = useState(false);
  const checkVisible = (inView, entry) => {
    if (inView) setVisible(true);
  };
  const { loading, error, data } = useQuery(SERVICES_QUERY);

  if (loading) return <Loading />;
  if (error) return <ErrorPage />;
  const services = data.servicesCollection.items;

  return (
    <section id='services'>
      <InView
        as='span'
        onChange={(inView, entry) => checkVisible(inView, entry)}
        threshold={0}
        triggerOnce={true}
      ></InView>
      <div
        className={`${
          isVisible
            ? ' nile-about-section container animate__animated animate__zoomIn'
            : ''
        }`}
      >
        <center className='section-title'>
          <i style={{ fontSize: 28, color: '#0981a3' }} class='fa fa-truck'></i>
          <br />
          <div className='h2'>Our Services</div>
        </center>
        <br />
        <div className='row'>
          <div className='col-lg-4'>
            {services.slice(0, services.length / 2).map((elem, index) => {
              return (
                <div class='service-icon-box'>
                  <div class='icon'>
                    <img src={elem.serviceIcon.url} alt='' />
                  </div>
                  <a href={`/service/${elem.serviceName}`} class='title h2'>
                    {elem.serviceName}
                  </a>
                  <div class='des'>{elem.shortDescription}</div>
                  <a href={`/service/${elem.serviceName}`}>
                    <button
                      className='btn btn-primary my-1'
                      style={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                      Learn More
                    </button>
                  </a>
                </div>
              );
            })}
          </div>
          <div class='col-lg-4 d-flex align-items-center'>
            <div class='text-center'>
              <img
                style={{ maxWidth: '100%', verticalAlign: 'middle' }}
                src={service1}
                alt=''
                class='border-radius-500 p-3'
              />
            </div>
          </div>
          <div className='col-lg-4'>
            {services.slice(services.length / 2).map((elem, index) => {
              return (
                <div class='service-icon-box'>
                  <div class='icon'>
                    <img src={elem.serviceIcon.url} alt='' />
                  </div>
                  <a href={`/service/${elem.serviceName}`} class='title h2'>
                    {elem.serviceName}
                  </a>
                  <div class='des'>{elem.shortDescription}</div>
                  <a href={`/service/${elem.serviceName}`}>
                    <button
                      className='btn btn-primary my-1'
                      style={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                      Learn More
                    </button>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
