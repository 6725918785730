import React from 'react';
import logo from '../assets/img/logo-dark-1-01.png';

const Header = () => {
  return (
    <div>
      <header>
        <nav className='navbar navbar-expand-lg navbar-light'>
          <button
            className='navbar-toggler w-100 align-items-center border-0'
            type='button'
            data-toggle='collapse'
            data-target='#navbarTogglerDemo01'
            aria-controls='navbarTogglerDemo01'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon float-right my-4'></span>
            <a href='/' className='navbar-brand float-left'>
              <img alt='logo' width={200} src={logo} />
            </a>
          </button>
          <div className='collapse navbar-collapse' id='navbarTogglerDemo01'>
            <a href='/' className='navbar-brand d-md-block d-none'>
              <img
                style={{ cursor: 'pointer' }}
                alt='logo'
                width={200}
                src={logo}
              />
            </a>
            <ul className='navbar-nav ml-auto mt-2 mt-lg-0'>
              <li className='nav-item'>
                <a href='/#' className='nav-link'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a href='/#about' className='nav-link'>
                  About US
                </a>
              </li>
              <li className='nav-item'>
                <a href='/#services' className='nav-link'>
                  Services
                </a>
              </li>

              <li className='nav-item'>
                <a href='/#contact' className='nav-link'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
