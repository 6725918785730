import { gql } from '@apollo/client';

export const SERVICES_QUERY = gql`
  {
    servicesCollection(order: order_ASC) {
      items {
        serviceName
        shortDescription
        serviceIcon {
          url
        }
      }
    }
  }
`;

export const SERVICES_QUERY_DETAILED = gql`
  query ($serviceName: String!) {
    servicesCollection(where: { serviceName: $serviceName }) {
      items {
        detailedDescription
      }
    }
  }
`;

export const ABOUT_US_QUERY = gql`
  {
    aboutUsCollection {
      items {
        description
        aboutImage {
          url
        }
      }
    }
  }
`;

export const WHY_US_QUERY = gql`
  {
    whyUsCollection(order: sys_publishedAt_ASC) {
      items {
        name
        icon {
          url
        }
      }
    }
  }
`;
