import { useQuery } from '@apollo/client';
import React from 'react';
import { SERVICES_QUERY_DETAILED } from '../../GQL/Quries';
import { ErrorPage } from '../ErrorPage';
import { Loading } from '../Loading';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';

const ServicePageTemplate = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(SERVICES_QUERY_DETAILED, {
    variables: { serviceName: id },
  });

  if (loading) return <Loading />;
  if (error || data.servicesCollection.items < 1) return <ErrorPage />;

  const markdown = data.servicesCollection.items[0].detailedDescription;
  return (
    <div style={{ minHeight: '100vh' }} className='container service-section'>
      <h4>{id}</h4>
      <br />
      <ReactMarkdown children={markdown} />
    </div>
  );
};

export default ServicePageTemplate;
