import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import App from './App';

import ServicePageTemplate from './Components/ServicesPage/ServicePageTemplate';
import ReactGA from 'react-ga';

const Router = () => {
  useEffect(() => {
    ReactGA.initialize('UA-209138114-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Switch>
      <Route exact path='/' render={(props) => <App {...props} />} />
      <Route
        path='/service/:id'
        render={(props) => <ServicePageTemplate {...props} />}
      />

      <Redirect from='*' to='/' />
    </Switch>
  );
};

export default Router;
