import { useQuery } from '@apollo/client';
import React from 'react';
import whyusIcon from '../assets/icons/whyus.png';
import { WHY_US_QUERY } from '../GQL/Quries';
import { ErrorPage } from './ErrorPage';
import { Loading } from './Loading';
import WhyUsCard from './WhyUsCard';
const WhyUs = () => {
  const { loading, error, data } = useQuery(WHY_US_QUERY);

  if (loading) return <Loading />;
  if (error || data.whyUsCollection.items < 1) return <ErrorPage />;
  const whyUs = data.whyUsCollection.items;
  return (
    <div id='whyus' className='container nile-fre-section'>
      <div className='section-title-right'>
        <div class='icon'>
          <img src={whyusIcon} alt='' />
        </div>
        <h4>Why Choose Us</h4>
        <br />
        <div className='row'>
          {whyUs.map((elem, index) => {
            return (
              <div className='col-lg-3 col-6'>
                <WhyUsCard
                  icon={elem.icon.url}
                  color={index % 2 === 0 ? 'default' : 'second'}
                  text={elem.name}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
