import React from 'react';
const WhyUsCard = ({ text, color, icon }) => {
  return (
    <div className={`jumbotron whyuscard-${color}`}>
      <div
        style={{
          backgroundImage: `url(${icon})`,
          width: '50px',
          height: '50px',
          backgroundOrigin: 'content-box',
          backgroundRepeat: 'no-repeat',
          marginBottom: '10px',
        }}
      ></div>

      <h5>{text}</h5>
    </div>
  );
};

export default WhyUsCard;
