import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ScrollToTop from './Components/ScrollToTop';

const client = new ApolloClient({
  uri: 'https://graphql.contentful.com/content/v1/spaces/s2ht54dm0tok/?access_token=4xHCK0MF-Vq462czbtDAdWHgoQVJkyzlALIDnH8c9bE',
  cache: new InMemoryCache(),
});
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Header />
        <Router />
        <Footer />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
