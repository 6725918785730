import React from 'react';
const Slider = () => {
  return (
    <section className='pt-5 pb-5 mt-0 align-items-center d-flex bg-dark hero'>
      <div className='container'>
        <div className='row align-items-center d-flex justify-content-between'>
          <div className='col-12 col-md-6 pb-5 order-2 order-sm-2 '>
            <h1 className='text-dark font-weight-bold mb-3 mt-5 display-4 animate__animated animate__fadeInUp'>
              SEA BOX EXPERTS SHIPPING LINE LLC
            </h1>

            <div className='d-flex mt-3 mb-1 animate__animated  animate__fadeInUp'>
              <a
                className='btn btn-primary btn-raised text-uppercase btn-lg  mt-md-3'
                role='button'
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
