import React from 'react';
import contactUsIcon from '../assets/icons/contact-us.png';
import AddressMap from './AddressMap';
const ContactUs = () => {
  return (
    <section id='contact' className='container nile-fre-section py-3 mb-3'>
      <div className='section-title-right'>
        <div class='icon'>
          <img src={contactUsIcon} alt='contact' />
        </div>
        <h4>Contact Us</h4>
        <br />
        <div className='row'>
          <div className='col-lg-6'>
            <h3>Address:</h3>
            <p>
              Office no. 1309, 13th Floor, Grosvenor Business Tower, Barsha
              Heights <br />
              TECOM. Dubai, UAE
            </p>
            <br />
            <div className='container'>
              <div className='contact-elements'>
                <i class='fa fa-phone' aria-hidden='true'></i>
                <p>+971 42367800</p>
              </div>
              <br />
              <div className='contact-elements'>
                <i
                  style={{ paddingLeft: 10 }}
                  class='fa fa-mobile'
                  aria-hidden='true'
                ></i>
                <p>+971 559575196</p>
              </div>
              <br />
              <div className='contact-elements'>
                <i class='fa fa-envelope' aria-hidden='true'></i>
                <p>info@sbeshippingline.com</p>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <form
              action='mailto:info@sbeshippingline.com'
              method='post'
              enctype='text/plain'
            >
              <div class='form-group'>
                <label for='exampleInputEmail1'>Email address</label>
                <input
                  type='text'
                  class='form-control'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder='Enter email'
                  required
                />
                <small id='emailHelp' class='form-text text-muted'>
                  We'll never share your email with anyone else.
                </small>
              </div>

              <div class='form-group'>
                <label for='exampleFormControlTextarea1'>Enter Message</label>
                <textarea
                  class='form-control'
                  name='message'
                  required
                  id='exampleFormControlTextarea1'
                  rows='3'
                ></textarea>
              </div>
              <button type='submit' class='btn btn-primary-form'>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <br />
      <AddressMap />
    </section>
  );
};

export default ContactUs;
